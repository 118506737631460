import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第一輪</h2>
          <h1 className="main_title">#機制執行</h1>
        </div>


      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_7">
            <figure className="figure">
              <img src={require("../../images/blog/post_2_3_2.jpg")} alt=""/>
              <figcaption className="figcaption left">組長：李慧珍<br/>組員：吳佩珊、沈伯丞、樂美成</figcaption>
            </figure>
          </div>
        </section>
        <section className="row_area_s">
          <div className="column_5">
            <div className="context">

              <p>成員進行投票，從數個子題中，選出三個最想討論的問題，來切入機制執行的討論：</p>

              <p>1. 公共藝術如何突破一般合約限制，實現長期經營</p>

              <p>2. 面對龐大資源，以及複雜的社宅社會議題，如何匯聚跨領域人才參與貢獻</p>

              <p>3. 執行預算如何導向積極，興利思維而非防弊</p>


              <p>首先成員以實際執行面的觀察，來反應合約規範，難以應變許多不可預期狀況的窘境。近期許多公共藝術，不再限於單一硬體的設置，而有更多關於永續或是環境相關的創作，後續的需求與變化很大，前期合約在對於經費材料、人事等彈性運用，難以回應。</p>


<p>組員提出類似法人機制或機構的運作，可有效回應以上議題、解決現行機制的限制。</p>

<p>以下整理組員對於法人機制運作的概念說明：</p>

<p>(1) 法人機構的成立有其特定目的──服務公共藝術的永續性。</p>

<p>(2) 法人本身具以下兩項特質：</p>

 <p>　• 公共性：法人只服務特定目的，其營利專用回應其目的，公共性依舊存在。</p>

 <p>　• 透明性：全民監督，不僅只是議會監督，運作得以被大家檢閱。</p>


<p>非單一型既定方案合約</p>

 <p>　• 空間的彈性：突破一般性合約限制。<br/>例如：「經營合約」，也許解決許多公共藝術作品的維護問題，透過營利合約回饋，可以彈性回應不同年限需要的維護預算。舉例，日本的許多大型藝術節即是有財團法人來和日本政府合作，透過經營合約的方式介入地方。</p>

 <p>　• 時間的靈活：回應不同時間的需求，進行經費與人才的調配，與時俱進。</p>

<p>　　❖ 經費：在不同的時間，可以藉由計畫型機制爭取資金，例如適合社區的補助案，或文化資產、空間維護等等。</p>


<p>　　❖ 人才：透過以下三種不同比例的配置，機構可以因應現在和未來的需求，讓適當的跨域人才有機會進來。</p>


<p>　　　a 其他跨域專才</p>


<p>　　　b 公部門：因法人母基金是利用公共性的資金組成，組成份子也許要有一定比例的公部門，為了銜接、理解、確認運作，而非為了進行管制。</p>


<p>　　　c 民眾代表：如管委會。讓住民的聲音與需求藉由管委會有機會進來，同時也確保得以與時俱進。</p>


<h5>→成為一個滾動永續經營的機制</h5>

<p>組員提出一個案例，10,000 Year Clock是由Danny Hillis為主的藝術計畫。這個時鐘每一年行走一格，將持續一萬年，挑戰人們對於時間的既有概念。此計畫執行時間很長，促成其一個非營利的法人­機構──<a href="http://longnow.org/" target="_blank">The Long Now Foundation</a>──的成立，此法人機構也推行其它被時間考驗的長久計畫。</p>


<p>法人機構母基金不能隨便運用，這些計畫雖然很難執行，但如同宗教一樣，基督教被傳下來不是因為經費，而是它的精神與想法被很多人繼承，此案例也是如此。</p>



            </div>
            <div className="context_s">
              <p>[註]<br/><a href="http://www.10000yearclock.net/index.html" target="_blank">10,000 Year Clock</a>計畫將一只高達152.4公尺的大的時鐘裝置建置於德州的山裡，耗費USD 42million。這個時鐘每一年行走一格，不時會撥放永遠不會重複撥放的音樂，並將持續一萬年，挑戰人們對於時間的既有概念。其中一位資金來源者為Amazon創辦人與執行長Jeff Bezos。</p>
            </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_2_4" className="next_page">
            <div className="next_title">#城市策略</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第一輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
